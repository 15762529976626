import {useState} from 'react';
import {Grid, Box} from '@jobseeker/lotus/lib/foundations';
import Image from 'next/image';
import {Typography, Link} from '@mui/material';
import {shallowEqual, useSelector} from 'react-redux';
import useLoadAds from '@jobseeker/common-core/lib/hooks/useLoadAds';
import {handleTracking} from '@jobseeker/common-core/lib/utils';

import type {LearningPartnerType} from '../types';
import type {AppState, AdsElementType} from '@jobseeker/common-core/lib/types';

const LearningPartner = (props: LearningPartnerType) => {
  const [learningInfo, setLearningInfo] = useState(Array<AdsElementType>);

  const {eventTag, heading, theme, slotId} = props || {};

  const learningPartners = useSelector(
    (state: AppState) => state.ads.learningPartners,
    shallowEqual
  );

  useLoadAds(slotId, setLearningInfo, {}, false, !learningPartners[0]?.title);

  const loadedLearningPartners = learningPartners[0]?.title
    ? learningPartners
    : learningInfo;

  return !loadedLearningPartners.length ? (
    <></>
  ) : (
    <Grid
      container
      width="100%"
      sx={{
        px: {xxs: 3, sm: 3, md: 5, lg: 8},
        margin: 0,
        textAlign: 'center',
      }}
    >
      <Grid
        item
        width="100%"
        paddingTop={theme.typography.pxToRem(30)}
        border={`${theme.spacing(0.125)} solid ${theme.palette.gray.light}`}
        borderTop={0}
        justifyContent="center"
      >
        <p
          style={{
            textAlign: 'center',
            fontSize: theme.typography.pxToRem(20),
            fontWeight: 400,
            margin: '0px auto',
            marginBottom: theme.spacing(5.625),
          }}
        >
          {heading}
        </p>
        <Grid
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignContent="flex-start"
          overflow="auto"
        >
          {loadedLearningPartners.map((partner, index) => (
            <Link
              key={`learnpartners-home-${index}`}
              onClick={() => {
                handleTracking(eventTag, {
                  event_category: 'engagement',
                  Label: partner['title'],
                  Value: `${index}`,
                });
                handleTracking('scClickViewLearningPartner', {
                  event_category: 'CourseShowcase',
                  Source: 'homepage',
                  PartnerId: partner['title'],
                  UserId: sessionStorage.getItem('userId') || 'NA',
                  Device: sessionStorage.getItem('device') || 'desktop',
                  Device_Type: sessionStorage.getItem('deviceType') || 'web',
                  Status: sessionStorage.getItem('status') || 'LoggedOut',
                  eventValue: `${index}`,
                });
              }}
              href={partner['link']}
              target="_blank"
              sx={{
                margin: `0 ${theme.spacing(2.5)}`,
                cursor: 'pointer',
                color: 'text.primary',
              }}
            >
              <Box
                sx={{
                  marginRight: theme.spacing(1),
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                <Image
                  width={50}
                  height={50}
                  src={partner['companyLogo'] || ''}
                  alt={partner['title']}
                />
              </Box>
              <Typography
                component="span"
                sx={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  fontSize: theme.typography.pxToRem(13.6),
                  height: theme.spacing(6.25),
                }}
              >
                {partner['title']}
              </Typography>
            </Link>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LearningPartner;
